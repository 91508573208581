<template>

    <div class="water-para">
        <a-spin :spinning="false">
            <div class="name-row">
                <div class="name">{{langRes[model.identifier]}}</div>
                <a-tag class="status" v-if="status==0" color="green">{{langRes.stNormal}}</a-tag>
                <a-tag class="status" v-if="status==-1" color="#FF4444">{{langRes.stLow}}</a-tag>
                <a-tag class="status" v-if="status==1" color="#FFEE00" style="color: #0084FF">{{langRes.stHigh}}</a-tag>
                <!--            <div class="status">{{status}}</div>-->
            </div>
            <div class="value-row">
                <div class="value">{{value}}</div>
                <div class="unit">{{model.unit}}</div>
            </div>
<!--            <div class="range">{{range}}</div>-->
        </a-spin>
    </div>

</template>

<script>
    export default {
        props: ["model", "valueMap","langRes"],
        computed: {
            range() {
                return this.model.min + " ~ " + this.model.max
            },
            value() {
                return this.model.value || this.valueMap[this.model.identifier] || "--"
            },
            status() {
                let min = parseFloat(this.model.min)
                let max = parseFloat(this.model.max)
                let value = this.model.value || parseFloat(this.valueMap[this.model.identifier])
                if (value < min) {
                    return -1
                } else if (value > max) {
                    return 1
                } else {
                    return 0
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .water-para {
        width: 23%;
        margin: 1%;
        padding: 15px 20px;

        .name-row {
            display: flex;
            align-items: flex-start;

            .name {
                flex: 1;
                font-size: 22%;
            }

            .status{
                margin-bottom: 10%;
            }
        }

        .value-row {
            display: flex;
            align-items: baseline;

            .value {
                font-size: 55%;
                margin-right: 15px;
                flex: 1;
                font-weight: 600;
            }

            .unit {
                font-size: 30%;
                opacity: 0.6;
                margin-right: 2%;
            }
        }


        .range {
            text-align: center;
            background: #F8F8F8;
            border-radius: 5px;
            padding: 3px;
            font-size: 12px;
        }

        .status{
            margin: 0;
            font-size: 17px;
            padding: 8px 12px;
        }
    }
</style>
